@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap");

body {
  background-image: var(--bg-url);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: var(--clr-primary-300);

  margin-inline: auto;
  padding: 2rem 1.5rem;
  max-width: 75rem;
}

@media (min-width: 48em) {
  body {
    --bg-url: url("../../assets/desktop/bg-pattern-header.svg");
    background-size: initial;
  }
}

@media (min-width: 66em) {
  body {
    background-size: contain;
  }
}
