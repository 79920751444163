.filters {
  background-color: var(--clr-primary-200);
  margin-top: 2rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border: none;
  border-radius: 0.375rem;
}
