.modal__checkbox__container {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.modal__checkbox {
  width: 1.5rem;
  height: 1.5rem;
  appearance: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;

  &:hover {
    background-color: var(--clr-secondary-300);
  }

  &:checked {
    background-color: var(--clr-secondary-400);
    background-image: url("../../assets/desktop/icon-check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.75rem;
  }
}

label[for="FulltimeCheckbox"] {
  font-weight: 700;
  margin-left: 1rem;
}
