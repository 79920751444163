.modalOverlay {
  position: fixed;
  inset: 0 0 0 0;
  background-color: black;
  opacity: 0.5;
}

.modalFilters {
  background-color: var(--clr-primary-200);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem;
  width: calc(100% - 3rem);
  max-width: 25em;
  border: none;
  border-radius: 0.375rem;
}
