.jobCards {
  margin-inline: auto;
  margin-top: 3.0625rem;
}

@media (min-width: 40em) {
  .jobCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.6875rem;
  }
}

@media (min-width: 66em) {
  .jobCards {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
  }
}
