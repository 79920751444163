.filter {
  height: 100%;
  background-color: var(--clr-primary-200);
  flex-grow: 1;
  opacity: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  border-radius: 0.375rem;

  &::placeholder {
    opacity: 0.5;
  }
}

.modal {
  width: 100%;
  background-color: var(--clr-primary-200);
  flex-grow: 1;
  opacity: 1;
  padding-bottom: 1.5rem;
  border: none;
  border-bottom: 1px solid rgba(110, 128, 152, 0.2);

  &::placeholder {
    opacity: 0.5;
  }
}
