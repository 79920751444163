.jobCard {
  background-color: var(--clr-primary-200);
  border-radius: 0.5rem;
  padding: 3.0625rem 0.5rem 2rem 2rem;
  position: relative;
  margin-bottom: 3.125rem;
  transition: all 0.2s ease-in-out;
}

.jobCard:hover {
  transform: scale(1.05);
}

.jobCard__logo {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 1rem;
  position: absolute;
  top: -1.5625rem;
}

.jobCard__created,
.jobCard__contractTime,
.jobCard__created + span {
  color: var(--clr-primary-500);
  margin-right: 0.3rem;
}

.jobCard__title {
  font-size: var(--fs-500);
  margin-top: 0.75rem;
}

.jobCard__company {
  margin-top: 1rem;
  color: var(--clr-primary-500);
}

.jobCard__location {
  margin-top: 2.75rem;
  font-weight: 700;
  font-size: var(--fs-300);
  color: var(--clr-secondary-400);
}

// ROUTER LINK RESET

.router__link {
  color: inherit;
}

// SKLETON STYLES

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  opacity: 0.75;
  border-radius: 0.3rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--clr-primary-300);
  }

  100% {
    background-color: var(--clr-primary-350);
  }
}

.jobCard__company.skeleton,
.jobCard__location.skeleton,
.jobCard__created.skeleton {
  height: var(--fs-400);
}

.jobCard__created.skeleton {
  width: 10rem;
}

.jobCard__title.skeleton {
  height: var(--fs-500);
  width: 240px;
}

.jobCard__company.skeleton {
  width: 6rem;
}

.jobCard__location.skeleton {
  width: 8rem;
}
