body,
input,
button {
  font-family: "Kumbh Sans", sans-serif;
  color: var(--clr-primary-600);
  font-size: var(--fs-400);
}

// VARIABLES //

body {
  --clr-secondary-400: hsla(235, 69%, 61%, 1);
  --clr-secondary-300: hsla(235, 82%, 77%, 1);

  --clr-primary-200: white;
  --clr-primary-300: hsla(217, 26%, 97%, 1);
  --clr-primary-350: rgb(214, 218, 228);
  --clr-primary-400: hsla(212, 23%, 69%, 1);
  --clr-primary-500: hsla(214, 17%, 51%, 1);
  --clr-primary-600: hsla(219, 29%, 14%, 1);

  --fs-300: 0.875rem;
  --fs-400: 1rem;
  --fs-500: 1.25rem;
  --fs-700: 2rem;

  --bg-url: url("../assets/mobile/bg-pattern-header.svg");
}

// THEME TRANSITION //

body {
  transition: background 0.5s;
}

// RESETS //

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 100%;
}
a,
a:visited,
a:active {
  text-decoration: none;
}
body {
  min-height: 100vh;
}
img,
picture {
  max-width: 100%;
  display: block;
  box-sizing: content-box;
}
li {
  list-style: none;
}
button:hover {
  cursor: pointer;
}
input[type="text"] {
  outline: none;
}

// removes animations for people who have turned them off
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
