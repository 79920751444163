.button {
  border: none;
  border-radius: 0.375rem;
  background-color: var(--clr-secondary-400);
  color: white;
  font-weight: 700;

  &:hover {
    background-color: var(--clr-secondary-300);
  }
}

.button--search,
.button--more {
  width: 3rem;
  height: 3rem;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}

.button--searchModal {
  font-size: var(--fs-400);
  padding: 1rem 0;
  width: 100%;
}

.button--search {
  background-image: url(../../assets/desktop/icon-search.svg);
}

.button--more {
  background-color: transparent;
  background-image: url(../../assets/mobile/icon-filter.svg);

  &:hover {
    background-color: transparent;
    opacity: 0.5;
  }
}

.button--moreJobs {
  display: block;
  margin-inline: auto;
  padding: 1rem 2rem;
}

.button--apply,
.button--back {
  display: block;
  text-align: center;
  width: 100%;
  padding: 1rem 0;
  margin-top: 1rem;
}

.button--back {
  background-color: hsla(235, 69%, 61%, 0.1);
  color: var(--clr-secondary-400);

  &:hover {
    background-color: hsla(235, 69%, 61%, 0.35);
  }
}

@media (min-width: 48em) {
  .button--apply {
    width: 9.25rem;
    margin: 0;
  }
  .button--back {
    width: 16rem;
    margin: 0 auto;
  }
}
