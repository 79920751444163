.toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  cursor: pointer;

  &::after {
    content: url(../../assets/desktop/icon-moon.svg);
  }

  &::before {
    content: url(../../assets/desktop/icon-sun.svg);
    height: 20px;
  }

  &:hover {
    .toggle__fill::after {
      background-color: var(--clr-secondary-300);
    }
  }
}

.toggle__fill {
  display: inline-block;
  position: relative;
  --width: 3rem;
  width: var(--width);
  height: 1.5rem;
  border-radius: 0.75rem;
  background-color: white;
  transition: background 0.2s;

  &::after {
    content: "";
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 5px;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 100%;
    background-color: var(--clr-secondary-400);
    transition: left 0.2s;
  }
}

.toggle__input {
  width: 3rem;

  // position checkbox behind toggle to make it focasable
  position: absolute;
  right: 28px;

  &:checked ~ .toggle__fill::after {
    left: calc(var(--width) - 0.875rem - 5px);
  }
}

// DARK MDOE VARIABLES

body.dark-mode {
  --clr-primary-200: hsla(219, 29%, 14%, 1);
  --clr-primary-300: hsla(219, 29%, 10%, 1);
  --clr-primary-350: rgb(52, 61, 89);
  --clr-primary-600: white;

  .filter__more {
    filter: brightness(0) saturate(100%) invert(100%);
  }

  input[type="checkbox"] {
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.35);
    }

    &:checked {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
