header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.title {
  color: white;
  font-size: var(--fs-700);
}
