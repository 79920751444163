.detail__created,
.detail__created + span,
.detail__contractTime {
  color: var(--clr-primary-500);
  margin-right: 0.3rem;
}

.detail__title,
.detail__location,
.detail__description {
  margin-top: 0.75rem;
}

.jobDetails {
  padding: 2.5rem 1.5rem;
  margin-top: 2rem;
}

.detail__title {
  font-size: var(--fs-500);
  color: var(--clr-primary-600);
}

.detail__location {
  font-size: var(--fs-300);
  font-weight: 700;
  color: var(--clr-secondary-400);
}

.detail__description {
  color: var(--clr-primary-500);
  line-height: 1.625rem;
  margin-bottom: 2rem;
}

.jobDetails,
.companyInfo {
  background-color: var(--clr-primary-200);
  border-radius: 0.5rem;
}

// company info

.companyInfo {
  position: relative;
  text-align: center;
  margin-top: 3.625rem;
  padding: 3.125rem 1.5rem 2rem 1.5rem;
}

.detail__company {
  font-size: var(--fs-500);
  font-weight: 700;
}

.detail__url {
  color: var(--clr-primary-500);
  margin-top: 0.8125rem;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.company__logo {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 1rem;
  position: absolute;
  top: -1.5625rem;
  left: 50%;
  transform: translateX(-50%);
}

// media queries

@media (min-width: 48em) {
  .companyInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5rem 0 0;
    height: 8.75rem;
    margin-top: 2.875rem;
  }

  .companyUrl {
    text-align: left;
    flex-grow: 1;
    margin-left: 2.5rem;

    .detail__url {
      margin-bottom: 0;
    }
  }

  .company__logo {
    position: relative;
    transform: initial;
    inset: initial;
    height: 100%;
    width: auto;
    border-radius: 0.45rem 0 0 0.45rem;
  }
}
